import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom'
import { ActionButton, Dialog, DialogType, Label, TextField, Text, Checkbox } from '@fluentui/react';
import { Spinner, SpinnerSize } from '@fluentui/react/lib/components/Spinner';
import { Stack, StackItem } from '@fluentui/react/lib/components/Stack';
import { css } from '@fluentui/react/lib/Utilities';

import { Common, DataProvider, HttpService, IDataProvider } from '../../dataprovider';
import { CardInfo, Country, IApplicationSettings, ICustomerInfo, IPaymentSettings, IPaymentTokenResponse, IValidateTokenResult, ValidationInfo } from '../../interfaces';

import './CustomerInfo.css';

export type PaymentProviderProps = {
    useShortToken?: boolean;
    paymentSettings: IPaymentSettings | undefined;
    dataProvider: IDataProvider;
    setErrorMessage: (message: string) => void;
    setSuccessMessage: (message: string) => void;
}

export const CustomerInfo: React.FunctionComponent<PaymentProviderProps> = (props: PaymentProviderProps) => {
    const { useShortToken, paymentSettings, dataProvider, setErrorMessage, setSuccessMessage } = props;
    const ApplicationLabels = Common.ApplicationLabels;

    const { search } = useLocation();
    let searchParams = new URLSearchParams(search);
    let token = searchParams.has('token') ? searchParams.get('token') : null;

    const history = useHistory();
    const [showSubmitButton, setShowSubmitButton] = useState<boolean>(false);
    const [country, setCountry] = useState<string | null>(null);
    const [sorg, setSORG] = useState<string | null>(null);
    const [currencyCode, setCurrencyCode] = useState<string | null>(null);
    const [isFormValid, setIsFormValid] = useState<boolean>(false);
    const [showPaymentSectionLoadingText, setShowPaymentSectionLoadingText] = useState<boolean>(true);
    const [customerInfo, setCustomerInfo] = useState<ICustomerInfo | null>(null);
    const [validationInfo, setValidationInfo] = useState<ValidationInfo>(() => getInitialValidationState());
    const [showWaitingSpinner, setShowWaitingSpinner] = useState<boolean>(false);
    const [rapidPay, setRapidPay] = useState<any | null>(null);
    const [tokenResponse, setTokenResponse] = useState<IPaymentTokenResponse | null>(null);
    const [tokenizeResponse, setTokenizeResponse] = useState<any | null>(null);
    const [signedTokenizeResponse, setSignedTokenizeResponse] = useState<any | null>(null);
    const [paymentCardDetails, setPaymentCardDetails] = useState<CardInfo | null>(null);
    const refCustomerInfo = useRef(customerInfo);
    const refValidationInfo = useRef(validationInfo);
    const refCountry = useRef(country);

    const initializeData = useCallback(() => {
        let promises: Promise<any>[] = [];

        if (!token) {
            history.push(`/invalidLink`);
        }
        else {

            promises.push(Common.loadScriptPromise(`${paymentSettings?.paymentURL}/sdk/sdk.bundle.js`));
            if (useShortToken) {
                promises.push(dataProvider.validatePaymentLinkToken(token));
            }
            else {
                promises.push(dataProvider.validatePaymentLinkTokenOld(token));
            }

            Promise.all(promises).then((result: any[]) => {

                const scriptLoaded: boolean = result[0];
                const validateTokenResult: IValidateTokenResult = result[1];

                if (validateTokenResult?.isValid && scriptLoaded) {

                    let payload = Common.formatInfoPayload(validateTokenResult.payload);
                    console.log("payload", payload);

                    let customerInfoNew = customerInfo ? { ...customerInfo } : {};
                    let validationInfoNew = validationInfo ? { ...validationInfo } : {};

                    if (payload.customerName) {
                        customerInfoNew.customerName = payload.customerName;
                        validationInfoNew.customerName!.isValid = true;
                    }

                    if (payload.customerEmail) {
                        customerInfoNew.customerEmail = payload.customerEmail;
                        validationInfoNew.customerEmail!.isValid = true;
                    }

                    setCurrencyCode(payload.currencyCode);
                    setCountry(payload.country);
                    setSORG(payload.sorg);

                    refCountry.current = payload.country;
                    refCustomerInfo.current = customerInfoNew;
                    refValidationInfo.current = validationInfoNew;

                    setCustomerInfo(customerInfoNew);
                    setValidationInfo(validationInfoNew);
                    initialiseDelegoPaymentWindow(payload.country, payload.sorg, payload.currencyCode);
                    setIsFormValid(true);
                }
                else {
                    if (!scriptLoaded)
                        setErrorMessage(ApplicationLabels.Error_Message_UnableToLoadPaymentGateway);
                    else if (validateTokenResult?.errorMessage)
                        setErrorMessage(ApplicationLabels[validateTokenResult.errorMessage] ?? validateTokenResult.errorMessage);
                }
            }).catch(error => {
                const errorMessage = Common.extractErrorMessageFromErrorObject(error);
                const generalMessage = `${ApplicationLabels.Error_Message_UnableToValidatePaymentLink}.\n Error Message: ${errorMessage}`;

                setShowWaitingSpinner(false);
                setErrorMessage(generalMessage);
            });


        }
    }, [useShortToken, paymentSettings, dataProvider, setErrorMessage, setSuccessMessage])

    useEffect(() => {
        initializeData();
    }, []);

    const initialiseDelegoPaymentWindow = async (country: string, sorg: string, currency: string) => {

        setShowPaymentSectionLoadingText(true);

        if (paymentSettings) {
            const tokenResponse: IPaymentTokenResponse = await dataProvider.getPaymentAccessToken?.();
            setTokenResponse(tokenResponse);
            const rapidPay = openRapidPay(paymentSettings, tokenResponse.token, country, sorg, currency, (window as any).DelegoRapidPay.Show.Checkout);
            setRapidPay(rapidPay);
        }
    }

    const onTokenizeSuccess = (tokenizeResponse: any, signedTokenizeResponse: any) => {
        const customerInfoNew: ICustomerInfo = refCustomerInfo.current ? { ...refCustomerInfo.current } : {};
        /*
         * Actions to take once RapidPay has tokenized a payment card
         */
        console.log("Tokenize Success...");
        console.log("tokenizeResponse: ", tokenizeResponse);
        console.log("signedTokenizeResponse: ", signedTokenizeResponse);

        let cardInfo: CardInfo = {
            cardToken: tokenizeResponse.payment.token,
            cardType: tokenizeResponse.payment.cardType,
            cardExpiryDate: `${tokenizeResponse.payment.cardExpiration.month}/${tokenizeResponse.payment.cardExpiration.year}`,
            cardholderName: tokenizeResponse.payment.cardholderName
        };

        customerInfoNew.customerCardInfo = { ...cardInfo };

        setTokenizeResponse(tokenizeResponse);
        setSignedTokenizeResponse(signedTokenizeResponse);
        setPaymentCardDetails(cardInfo);
        setCustomerInfo(customerInfoNew);
        refCustomerInfo.current = customerInfoNew;

        const validationResult = validateForm(customerInfoNew, refValidationInfo.current, refCountry.current!);

        setValidationInfo(validationResult.validationInfo);

        if (validationResult.isValid) {
            submitRequest(customerInfoNew);
        } else {
            setShowSubmitButton(true);
        }
    }

    const openRapidPay = (paymentSettings: IPaymentSettings, authorizationToken: string | undefined, country: string, sorg: string, currency: string, show: number) => {

        setShowPaymentSectionLoadingText(false);

        return (window as any).DelegoRapidPay.open({
            url: paymentSettings?.paymentURL,
            token: authorizationToken,
            show: show,
            sorg: sorg,
            locale: navigator.language,
            paymentRequest: {
                /*
                 * Payment request object
                 */
                currencyCode: currency,
                total: 0
            },
            element: '#paymentInfoContainer',
            iframeclass: 'paymentGatewayIframe',
            appclass: 'straumann-dentogo-payment-app',
            events: {
                onApplicationInitialized: () => {
                    /*
                     * Actions to take once RapidPay is displayed
                     */

                    console.log("Application Initialized...");

                    setShowPaymentSectionLoadingText(false);
                },
                onTokenizeSuccess: onTokenizeSuccess,
                onFailure: (error: any) => {
                    /*
                     * Actions to take if RapidPay fails to tokenize a payment card (typically a system error)
                     */
                    console.log("Tokenize Failure...", error);
                    setShowPaymentSectionLoadingText(false);
                }
            },
            /*
             * Additional optional parameters, as desired.
             */
        });
    }

    const onChangeTextField = (fieldName: keyof ICustomerInfo) => (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string | undefined) => {
        const customerInfoNew: ICustomerInfo = customerInfo ? { ...customerInfo } : {};
        const validationInfoNew: ValidationInfo = validationInfo ? { ...validationInfo } : {};

        if (fieldName !== "customerCardInfo" && fieldName != "consentProvided") {
            customerInfoNew[fieldName] = newValue;

            if (!customerInfoNew[fieldName] || !(customerInfoNew[fieldName] as string).trim()) {
                validationInfoNew[fieldName] = {
                    isValid: false,
                    errorMessage: Common.ApplicationLabels.Error_Message_Required
                }
            }
            else {
                if (fieldName.toLowerCase().indexOf("email") >= 0) {
                    const emails = (customerInfoNew[fieldName] as string).split(",");
                    let hasInvalidEmail = false;
                    for (const email of emails) {
                        if (!Common.validEmail(email.trim())) {
                            hasInvalidEmail = true;
                            validationInfoNew[fieldName] = {
                                isValid: false,
                                errorMessage: Common.ApplicationLabels.Error_Message_InvalidEmail
                            }
                        }
                    }

                    if (!hasInvalidEmail) {
                        validationInfoNew[fieldName] = {
                            isValid: true,
                            errorMessage: null
                        }
                    }
                    
                }
                else {
                    validationInfoNew[fieldName] = {
                        isValid: true,
                        errorMessage: null
                    }
                }
            }

            setCustomerInfo(customerInfoNew);
            refCustomerInfo.current = customerInfoNew;
            setValidationInfo(validationInfoNew);
        }

    };

    const onChangeCheckbox = (fieldName: keyof ICustomerInfo) => (ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
        const customerInfoNew: ICustomerInfo = customerInfo ? { ...customerInfo } : {};
        const validationInfoNew: ValidationInfo = validationInfo ? { ...validationInfo } : {};

        switch (fieldName) {
            case "consentProvided":
                customerInfoNew[fieldName] = checked;

                if (checked) {
                    validationInfoNew[fieldName] = {
                        isValid: true,
                        errorMessage: null
                    }
                }
                else {
                    validationInfoNew[fieldName] = {
                        isValid: false,
                        errorMessage: Common.ApplicationLabels.Error_Message_ConsentNotGiven
                    }
                }

                setCustomerInfo(customerInfoNew);
                refCustomerInfo.current = customerInfoNew;
                setValidationInfo(validationInfoNew);
        }

    };


    const onSubmit = async () => {
        const validationResult = validateForm(customerInfo, validationInfo, country!);

        setValidationInfo(validationResult.validationInfo);

        if (validationResult.isValid) {
            submitRequest(customerInfo!);
        } else {
            setShowSubmitButton(true);
        }
    }

    const submitRequest = async (customerInfo: ICustomerInfo) => {
        try {
            setShowWaitingSpinner(true);
            if (useShortToken)
                await dataProvider.submitPaymentDetails(customerInfo, token!);
            else
                await dataProvider.submitPaymentDetailsOld(customerInfo, token!);

            setShowWaitingSpinner(false);
            history.push(`/success`, { isValid: true });
        } catch (error) {
            const errorMessage = Common.extractErrorMessageFromErrorObject(error);
            const generalMessage = `${ApplicationLabels.Error_Message_RequestSubmissionFailed}.\n Error Message: ${errorMessage}`;

            setErrorMessage(generalMessage);
        }
    }

    if (!country)
        return (
            <Stack horizontalAlign="center" verticalAlign="center">
                <Spinner size={SpinnerSize.large} label="Loading Application Settings..." ></Spinner>
            </Stack>
        );
    else
        return (
            <>
                <div className={country === Country.USA || country === Country.BRAZIL ? "customerDetailsOneColumn" : "customerDetails"}>
                    <Stack className={country === Country.USA || country === Country.BRAZIL ? "customerInfoOneColumn" : "customerInfo"} verticalFill tokens={{ childrenGap: 15 }}>
                        {
                            country !== Country.USA && country !== Country.BRAZIL &&
                            <Stack className="row" horizontal horizontalAlign="start" verticalAlign="center" tokens={{ childrenGap: '8 0' }} wrap>
                                <StackItem className="label" verticalFill shrink={1} grow={1}>
                                    <h1 className="pageHeading">{ApplicationLabels.PageHeading_CustomerInfo}</h1>
                                </StackItem>
                            </Stack>
                        }
                        <Stack className="row" horizontal horizontalAlign="start" verticalAlign="center" tokens={{ childrenGap: '8 0' }} wrap>
                            <StackItem className="label" verticalFill shrink={1} grow={1}>
                                <Label required htmlFor="customerName">{ApplicationLabels.Label_Name}</Label>
                            </StackItem>
                            <StackItem className="field">
                                {
                                    country !== Country.USA && country !== Country.BRAZIL ?
                                        <TextField id="customerName" value={customerInfo?.customerName} onChange={onChangeTextField("customerName")} errorMessage={validationInfo?.customerName?.errorMessage ?? undefined} ></TextField>
                                        :
                                        <Text>{customerInfo?.customerName}</Text>
                                }
                            </StackItem>
                        </Stack>
                        <Stack className="row" horizontal horizontalAlign="start" verticalAlign="center" tokens={{ childrenGap: '8 0' }} wrap>
                            <StackItem className="label" verticalFill shrink={1} grow={1}>
                                <Label required htmlFor="customerEmail">{ApplicationLabels.Label_Email}</Label>
                            </StackItem>
                            <StackItem className="field">
                                {
                                    country !== Country.USA && country !== Country.BRAZIL ?
                                        <TextField id="customerEmail" value={customerInfo?.customerEmail} onChange={onChangeTextField("customerEmail")} errorMessage={validationInfo?.customerEmail?.errorMessage ?? undefined} ></TextField>
                                        :
                                        <Text>{customerInfo?.customerEmail}</Text>
                                }
                            </StackItem>
                        </Stack>
                        {
                            country === Country.BRAZIL &&
                            <Stack className="row consent" horizontalAlign="start" verticalAlign="center" tokens={{ childrenGap: '8 0' }} wrap>
                                <StackItem className="label" verticalFill shrink={1} grow={1}>
                                    <Checkbox id="consentProvided" required={true} label={ApplicationLabels.Label_Consent} styles={{ label: { fontWeight: 600 } }} checked={customerInfo?.consentProvided} onChange={onChangeCheckbox("consentProvided")} />
                                </StackItem>
                                {
                                    !validationInfo?.consentProvided?.isValid &&
                                    <StackItem className="field">
                                        <Text className="errorMessage">{validationInfo?.consentProvided?.errorMessage}</Text>
                                    </StackItem>
                                }

                            </Stack>
                        }
                        {
                            country !== Country.USA && country !== Country.BRAZIL &&
                            <>
                                <Stack className="row" horizontal horizontalAlign="start" verticalAlign="center" tokens={{ childrenGap: '8 0' }} wrap>
                                    <StackItem className="label" verticalFill shrink={1} grow={1}>
                                        <Label required htmlFor="customerContactNumber">{ApplicationLabels.Label_ContactNumber}</Label>
                                    </StackItem>
                                    <StackItem className="field">
                                        <TextField id="customerContactNumber" onChange={onChangeTextField("customerContactNumber")} errorMessage={validationInfo?.customerContactNumber?.errorMessage ?? undefined}></TextField>
                                    </StackItem>
                                </Stack>
                                <Stack className="row" horizontal horizontalAlign="start" verticalAlign="center" tokens={{ childrenGap: '8 0' }} wrap>
                                    <StackItem className="label" verticalFill shrink={1} grow={1}>
                                        <Label htmlFor="customerMobileNumber">{ApplicationLabels.Label_MobileNumber}</Label>
                                    </StackItem>
                                    <StackItem className="field">
                                        <TextField id="customerMobileNumber" onChange={onChangeTextField("customerMobileNumber")} errorMessage={validationInfo?.customerMobileNumber?.errorMessage ?? undefined} ></TextField>
                                    </StackItem>
                                </Stack>
                                <Stack className="row" horizontal horizontalAlign="start" verticalAlign="center" tokens={{ childrenGap: '8 0' }} wrap>
                                    <StackItem className="label" verticalFill shrink={1} grow={1}>
                                        <Label required htmlFor="customerAddress">{ApplicationLabels.Label_Address}</Label>
                                    </StackItem>
                                    <StackItem className="field">
                                        <TextField id="customerAddress" multiline rows={3} onChange={onChangeTextField("customerAddress")} errorMessage={validationInfo?.customerAddress?.errorMessage ?? undefined} ></TextField>
                                    </StackItem>
                                </Stack>
                            </>
                        }
                    </Stack>
                    {
                        isFormValid &&
                        <div className={country === Country.USA || country === Country.BRAZIL ? "paymentInfoParentContainerOneColumn" : "paymentInfoParentContainer"}>
                            <div className={css("paymentInfoContainer", { "error": validationInfo.customerCardInfo!.errorMessage ? true : false })} id="paymentInfoContainer">
                                {
                                    showPaymentSectionLoadingText &&
                                    <Spinner size={SpinnerSize.large} label="Loading payment window..." ariaLive="assertive" />
                                }
                            </div>
                            {
                                validationInfo.customerCardInfo?.errorMessage ?
                                    <div className="errorMessageContainer">
                                        <span className="errorMessage">{ApplicationLabels.Error_Message_MissingCardDetails}</span>
                                    </div>
                                    : null
                            }
                        </div>
                    }
                    {
                        showSubmitButton &&
                        <Stack className={country === Country.USA || country === Country.BRAZIL ? "commandBarOneColumn" : "commandBar"}>
                            <ActionButton checked={true} className="sumitButton" text={ApplicationLabels.Label_Button_Submit} onClick={onSubmit} ></ActionButton>
                        </Stack>
                    }
                </div>

                <Dialog
                    hidden={!showWaitingSpinner}
                    dialogContentProps={{
                        type: DialogType.normal,
                        showCloseButton: false
                    }}
                    modalProps={{
                        isBlocking: true,
                        className: "waitingSpinner"
                    }}
                >
                    <Spinner size={SpinnerSize.large} label={ApplicationLabels.Label_Spinner_InProgress} ariaLive="assertive" />
                </Dialog>
            </>
        );
}

const getInitialValidationState = (): ValidationInfo => {

    return {
        customerName: {
            isValid: false,
            errorMessage: null
        },
        customerEmail: {
            isValid: false,
            errorMessage: null
        },
        customerContactNumber: {
            isValid: false,
            errorMessage: null
        },
        customerMobileNumber: {
            isValid: false,
            errorMessage: null
        },
        customerAddress: {
            isValid: false,
            errorMessage: null
        },
        consentProvided: {
            isValid: false,
            errorMessage: null
        },
        customerCardInfo: {
            isValid: false,
            errorMessage: null
        },
    };
}

const validateForm = (customerInfo: ICustomerInfo | null, currentValidationInfo: ValidationInfo, country: string): { isValid: boolean; validationInfo: ValidationInfo } => {

    let isValid = true;

    for (const key in currentValidationInfo) {
        if (key === "customerMobileNumber")
            continue;

        if ((country === Country.USA || country === Country.BRAZIL) && (key === "customerContactNumber" || key === "customerAddress"))
            continue;

        let mappedKey = key as keyof ICustomerInfo;

        if (mappedKey === "consentProvided") {
            if (country === Country.BRAZIL && !customerInfo?.[mappedKey]) {
                isValid = false;
                currentValidationInfo[mappedKey] = {
                    isValid: false,
                    errorMessage: Common.ApplicationLabels.Error_Message_ConsentNotGiven
                }
            }
            continue;
        }


        if (!customerInfo?.[mappedKey] || (typeof customerInfo[mappedKey] === "string" && !(customerInfo[mappedKey] as string).trim())) {
            isValid = false;
            currentValidationInfo[mappedKey] = {
                isValid: false,
                errorMessage: Common.ApplicationLabels.Error_Message_Required
            }
        }
        else {
            if (key.toLowerCase().indexOf("email") >= 0) {
                const emails = (customerInfo[mappedKey] as string).split(",");
                let hasInvalidEmail = false;
                for (const email of emails) {
                    if (!Common.validEmail(email.trim())) {
                        hasInvalidEmail = true;
                        currentValidationInfo[mappedKey] = {
                            isValid: false,
                            errorMessage: Common.ApplicationLabels.Error_Message_InvalidEmail
                        }
                    }
                }

                if (!hasInvalidEmail) {
                    currentValidationInfo[mappedKey] = {
                        isValid: true,
                        errorMessage: null
                    }
                }

            }
            else {
                currentValidationInfo[mappedKey] = {
                    isValid: true,
                    errorMessage: null
                }
            }

        }

    }

    return {
        isValid: isValid,
        validationInfo: { ...currentValidationInfo }
    };

}

