import * as React from 'react';
import './Layout.css';
import logo from '../../images/str-group-white.svg';
import { Stack } from '@fluentui/react/lib/components/Stack/Stack';

export const Layout: React.FunctionComponent<any> = (props: any) => {
    const year = new Date().getFullYear();

    return (
        <>
            <header className="header">
                <a className="appName" onClick={() => { window.location.href = window.location.origin; }}>Payment App™</a>
                <img className="companyLogo" src={logo} alt="Straumann Logo"></img>
            </header>
            <main className="mainContent">
                <div className="container">{props.children}</div>
            </main>
            <footer className="footer">
                <Stack horizontal horizontalAlign="start" verticalAlign="center">© {year} Institut Straumann AG</Stack>
            </footer>
        </>

    );

}