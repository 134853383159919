export class HttpService {
    public static SPSiteUrl: string;
    public static QueryString: string;

    private static getHeaders(): HeadersInit {
        if (HttpService.SPSiteUrl) {
            return {
                'Accept': 'application/json',
                'Site-URL': HttpService.SPSiteUrl,
                'QueryParameter': HttpService.QueryString,
                'Content-Type': 'application/json',
            };
        }
        else {
            return {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            };
        }
    }

    public static async get(url: string, body?: any): Promise<any> {
        let response: Response;

        if (body)
            response = await fetch(url, {
                method: 'GET',
                cache: 'no-cache',
                headers: HttpService.getHeaders(),
                body: JSON.stringify(body)
            });
        else
            response = await fetch(url, {
                method: 'GET',
                cache: 'no-cache',
                headers: HttpService.getHeaders(),
            });

        return HttpService.handleResponse(response);

    }

    public static async post(url: string, body: any): Promise<any> {
        const response = await fetch(url, {
            method: 'POST',
            headers: HttpService.getHeaders(),
            body: JSON.stringify(body)
        });

        return HttpService.handleResponse(response);
    }

    public static async handleResponse(response: Response) {

        if (response.ok)
            return response.json();
        else {
            let errorString: string = "";
            try {
                errorString = await response.text();
                const error = JSON.parse(errorString);
                throw Error(error);
            } catch (error) {
                throw Error(errorString);
            }

        }
    }

}