import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { Switch, Route, matchPath, match, useLocation } from "react-router-dom";

import './custom.css'
import { Layout, CustomerInfo } from './components';
import { IApplicationSettings } from './interfaces';
import { Common, DataProvider, HttpService, IDataProvider } from './dataprovider';
import { Spinner, SpinnerSize } from '@fluentui/react/lib/components/Spinner';
import { Stack } from '@fluentui/react/lib/Stack';
import { MessageBar, MessageBarType } from '@fluentui/react/lib/MessageBar';
import { SuccessMessageComponent } from './components/SuccessMessageComponent';
import { InvalidPaymentLinkComponent } from './components/InvalidPaymentLinkComponent';

const App: React.FunctionComponent<any> = () => {
    const { search, pathname } = useLocation();
    let searchParams = new URLSearchParams(search);

    const dataProvider: DataProvider = new DataProvider();

    const siteUrl = useMemo(() => {
        const matchInfo: match<string> | null = matchPath("/paymentInfoLink", pathname);
        console.log("[PATH MATCH]: ", matchInfo, pathname);
        //if (matchInfo) {
        //    return searchParams.has('from') ? decodeURIComponent(searchParams.get('from')!) : null;
        //}

        return searchParams.has('from') ? decodeURIComponent(escape(atob(searchParams.get('from')!))) : null;
    }, [pathname])

    const [loadingApplicationSetting, setLoadingApplicationSetting] = useState<boolean>(true);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [successMessage, setSuccessMessage] = useState<string | null>(null);
    const [applicationSettings, setApplicationSettings] = useState<IApplicationSettings | undefined>(undefined);

    useEffect(() => {

        HttpService.SPSiteUrl = siteUrl!;
        HttpService.QueryString = search;

        dataProvider.getApplicationSettings(navigator.language).then((applicationSettings: IApplicationSettings) => {
            Common.setApplicationLabels(applicationSettings.applicationLabels);
            setApplicationSettings(applicationSettings);
            setLoadingApplicationSetting(false);
        }).catch(error => {
            const errorMessage = Common.extractErrorMessageFromErrorObject(error);
            const generalMessage = `An error occurred while getting application settings.\n Error Message: ${errorMessage}`;
            setErrorMessage(generalMessage);
        });

    }, [])

    const onDismiss = useCallback(() => {
        setErrorMessage(null);
        setSuccessMessage(null);
    },[])

    return (
        <Layout>
            {
                errorMessage || successMessage ?
                    <Stack className="width100">
                        <MessageBar
                            messageBarType={errorMessage ? MessageBarType.error : successMessage ? MessageBarType.success : MessageBarType.info}
                            isMultiline={false}
                            truncated={true}
                            onDismiss={onDismiss}
                            dismissButtonAriaLabel="Close"
                        >{errorMessage ?? successMessage}</MessageBar>
                    </Stack>
                    : null
            }
            {
                loadingApplicationSetting ?
                    <Stack horizontalAlign="center" verticalAlign="center">
                        <Spinner size={SpinnerSize.large} label="Loading Application Settings..." ></Spinner>
                    </Stack>
                    :
                    <Switch>
                        <Route path="/success" exact={true}>
                            <SuccessMessageComponent />
                        </Route>
                        <Route path="/invalidLink" exact={true}>
                            <InvalidPaymentLinkComponent />
                        </Route>
                        <Route path="/paymentLink">
                            <CustomerInfo
                                dataProvider={dataProvider}
                                paymentSettings={applicationSettings?.paymentSettings}
                                setErrorMessage={setErrorMessage}
                                setSuccessMessage={setSuccessMessage}
                            />
                        </Route>
                        <Route path="/paymentInfoLink">
                            <CustomerInfo
                                useShortToken
                                dataProvider={dataProvider}
                                paymentSettings={applicationSettings?.paymentSettings}
                                setErrorMessage={setErrorMessage}
                                setSuccessMessage={setSuccessMessage}
                            />
                        </Route>
                        <Route path="/*" >
                            <InvalidPaymentLinkComponent />
                        </Route>
                    </Switch>
            }
        </Layout>
    );

}

export default App;
