import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import registerServiceWorker, { unregister as unregisterServiceWorker }  from './registerServiceWorker';
import { initializeIcons } from '@fluentui/font-icons-mdl2';

initializeIcons();

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href') ?? undefined;
const rootElement = document.getElementById('root');

ReactDOM.render(
    <BrowserRouter basename={baseUrl}>
        <App />
    </BrowserRouter>,
    rootElement);

unregisterServiceWorker();

