import { Configuration, PopupRequest } from "@azure/msal-browser";
import { IApplicationLabels, IPaymentLinkRequest } from "../interfaces";

export class Common {
    public static _applicationLabels: IApplicationLabels = {
        PageHeading_CustomerInfo: "Customer Details",
        Label_Name: "Name",
        Label_Email: "Email",
        Label_ContactNumber: "Contact Number",
        Label_MobileNumber: "Mobile Number",
        Label_Address: "Address",
        Label_Consent: "I allow Straumann to store my Credit Card information for future purchases",
        Label_Spinner_InProgress: "Working on it...",
        Label_Button_Submit: "Submit",
        Error_Message_InvalidEmail: "Invalid Email Address",
        Error_Message_ConsentNotGiven: "Please provide your consent to proceed",
        Error_Message_Required: "Required",
        Error_Message_UnableToValidatePaymentLink: "Unable to validate payment link.",
        Error_Message_UnableToLoadPaymentGateway: "Unable to load payment gateway.",
        Error_Message_TokenExpired: "Payment link expired. Please ask Straumann representative to generate new one for you.",
        Error_Message_InvalidToken: "Payment link not originated from Straumann. (Malformed link)",
        Error_Message_MissingCardDetails: "Please enter card details and click on Submit button.",
        Error_Message_RequestSubmissionFailed: "Failed to submit request.",
        Error_Message_InvalidPaymentLink: "Invalid payment link.",
        Success_Message_RequestSubmissionSuccessfull: "Payment details submitted successfully."
    };

    static get ApplicationLabels() {
        return this._applicationLabels;
    }

    public static setApplicationLabels(applicationLabels: IApplicationLabels) {
        this._applicationLabels = {
            ...this._applicationLabels, ...applicationLabels
        };
    }

    public static msalConfig: Configuration = {
        auth: {
            clientId: "31a7837e-9bfe-4c4d-ae2e-4482e661e36e",
            authority: "https://login.microsoftonline.com/4274e8da-b2ee-444a-a6c8-249ffcfc1e74",
        }
    };

    // Add here scopes for id token to be used at MS Identity Platform endpoints.
    public static loginRequest: PopupRequest = {
        scopes: ["User.Read"]
    };

    // Add here the endpoints for MS Graph API services you would like to use.
    public static graphConfig = {
        graphMeEndpoint: "https://graph.microsoft.com/v1.0/me"
    };

    public static loadScript(scriptURL: string, callbackFunction?: Function) {
        const script = document.createElement("script");

        script.src = scriptURL;
        script.async = true;

        document.body.appendChild(script);
        if (callbackFunction) {
            script.onload = () => {
                return callbackFunction();
            }
        }

    }

    public static loadScriptPromise(scriptURL: string) {
        return new Promise((resolve, reject) => {
            const script = document.createElement("script");

            script.type = 'text/javascript';
            script.src = scriptURL;
            script.async = true;

            document.body.appendChild(script);
            script.onerror = (err) => {
                Common.logErrorInConsole("Error occured while loading script from URL: ", scriptURL);
                Common.logErrorInConsole("", Common.extractErrorMessageFromErrorObject(err));
                resolve(false);
            };

            script.onload = () => {
                resolve(true);
            }
        });
    }

    public static logErrorInConsole(errorArea: string, errorObj: any) {
        this.logError(errorObj.message);
        console.log(`Error message ${errorArea}: `, errorObj.message);
        console.log(`Error Stack Trace ${errorArea}: `, errorObj.stack);
    }

    public static logError(msg: any) {
        if (console) {
            console.log(`%cERROR:`, "color:red;font-weight:bold;font-size:16px");
            console.log(msg);
        }
    }

    public static validEmail(email: string) {
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    public static extractErrorMessageFromErrorObject(error: any): string {

        let errorMessage = error.message ? error.message as string : error as string;

        if (errorMessage.indexOf("::>") >= 0) {
            try {
                let errorObj = JSON.parse(errorMessage.substring(errorMessage.indexOf("::>") + 3).trim());

                if (errorObj["odata.error"] && errorObj["odata.error"].message && errorObj["odata.error"].message.value)
                    errorMessage = errorObj["odata.error"].message.value;

            } catch (error) {
                errorMessage = errorMessage.substring(errorMessage.indexOf("::>") + 3).trim();
            }
        }

        return errorMessage;
    }

    public static formatInfoPayload(payload:any): IPaymentLinkRequest  {
        return {
            country: payload["country"] ?? payload["Country"],
            currencyCode: payload["currencyCode"] ?? payload["CurrencyCode"],
            customerEmail: payload["customerEmail"] ?? payload["CustomerEmail"],
            customerName: payload["customerName"] ?? payload["CustomerName"],
            sorg: payload["sorg"] ?? payload["SORG"],
            customerSAPId: payload["customerSAPId"] ?? payload["CustomerSAPId"],
        }
    }

}