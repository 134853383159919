import React, { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Common } from "../dataprovider";

export const SuccessMessageComponent: React.FunctionComponent<any> = (props: any) => {
    const { state } = useLocation();
    const history = useHistory();

    useEffect(()=>{
        if(!state || !(state as any)?.isValid)
        history.push(`/invalidLink`);
    },[]);

    return (
        <div className="successMessage"><h1>{Common.ApplicationLabels.Success_Message_RequestSubmissionSuccessfull}</h1></div>
    );
}