import { IDataProvider } from "./IDataProvider";
import { HttpService } from "./HttpService";
import { IApplicationLabels, IApplicationSettings, ICustomerInfo, IPaymentSettings, IPaymentTokenResponse, IValidateTokenResult, PaymentDetailsRequest } from "../interfaces";

export class DataProvider implements IDataProvider {

    public apiEndpointPayment = `/api/Payment`;
    public apiEndpointAppSettings = `/api/Application`;

    public async getApplicationSettings(language: string | null): Promise<IApplicationSettings> {
        const applicationSettings: IApplicationSettings = await HttpService.get(`${this.apiEndpointAppSettings}/GetApplicationSettings/${language}`);
        return applicationSettings;
    }

    public async getApplicationLables(language: string | null): Promise<IApplicationLabels> {
        const applicationLabels: IApplicationLabels = await HttpService.get(`${this.apiEndpointAppSettings}/GetApplicationLabels/${language}`);
        return applicationLabels;
    }

    public async getPaymentSettings(): Promise<IPaymentSettings> {
        const paymentSettings: IPaymentSettings = await HttpService.get(`${this.apiEndpointPayment}/GetPaymentSettings`);
        return paymentSettings;
    }

    public async getPaymentAccessToken(): Promise<IPaymentTokenResponse> {
        const paymentAccessTokenResponse: IPaymentTokenResponse = await HttpService.get(`${this.apiEndpointPayment}/GetAccessToken`);
        return paymentAccessTokenResponse;
    }

    public async validatePaymentToken(token: string, tokenID: string): Promise<any> {
        const decodedToken: any = await HttpService.get(`${this.apiEndpointPayment}/ValidateToken/${token}/${tokenID}`);
        return decodedToken;
    }

    public async validatePaymentLinkTokenOld(token: string): Promise<IValidateTokenResult> {
        const validateTokenResult: IValidateTokenResult = await HttpService.get(`${this.apiEndpointAppSettings}/ValidatePaymentLinkTokenOld?token=${encodeURIComponent(token)}`);
        return validateTokenResult;
    }

    public async validatePaymentLinkToken(token: string): Promise<IValidateTokenResult> {
        const validateTokenResult: IValidateTokenResult = await HttpService.get(`${this.apiEndpointAppSettings}/ValidatePaymentLinkToken?token=${encodeURIComponent(token)}`);
        return validateTokenResult;
    }

    public async submitPaymentDetailsOld(customerInfo: ICustomerInfo, token: string): Promise<boolean> {
        const paymentDetailsRequest: PaymentDetailsRequest = {
            Locale: navigator.language,
            Token: token,
            CustomerCardInfo: {
                CardExpiryDate: customerInfo.customerCardInfo!.cardExpiryDate,
                CardToken: customerInfo.customerCardInfo!.cardToken,
                CardholderName: customerInfo.customerCardInfo!.cardholderName,
                CardType: customerInfo.customerCardInfo!.cardType,
            },
            CustomerInfo: {
                CustomerName: customerInfo.customerName,
                CustomerEmail: customerInfo.customerEmail,
                CustomerContactNumber: customerInfo.customerContactNumber,
                CustomerMobileNumber: customerInfo.customerMobileNumber,
                CustomerAddress: customerInfo.customerAddress
            }
        }
        
        const result: boolean = await HttpService.post(`${this.apiEndpointAppSettings}/SubmitCustomerDetailsOld`, paymentDetailsRequest);

        return result;
    }

    public async submitPaymentDetails(customerInfo: ICustomerInfo, token: string): Promise<boolean> {
        const paymentDetailsRequest: PaymentDetailsRequest = {
            Locale: navigator.language,
            Token: token,
            CustomerCardInfo: {
                CardExpiryDate: customerInfo.customerCardInfo!.cardExpiryDate,
                CardToken: customerInfo.customerCardInfo!.cardToken,
                CardholderName: customerInfo.customerCardInfo!.cardholderName,
                CardType: customerInfo.customerCardInfo!.cardType,
            },
            CustomerInfo: {
                CustomerName: customerInfo.customerName,
                CustomerEmail: customerInfo.customerEmail,
                CustomerContactNumber: customerInfo.customerContactNumber,
                CustomerMobileNumber: customerInfo.customerMobileNumber,
                CustomerAddress: customerInfo.customerAddress
            }
        }

        const result: boolean = await HttpService.post(`${this.apiEndpointAppSettings}/SubmitCustomerDetails`, paymentDetailsRequest);

        return result;
    }
}