export enum PaymentSteps {
    Tokenize,
    Authenticate,
    Authorize,
    CreateSalesOrder,
}

export enum Country {
    USA = "USA",
    JAPAN = "Japan",
    BRAZIL = "Brazil"
}